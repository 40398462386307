import React from 'react';
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig';

export const publicRoutes = [
  {
    key: 'login',
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() =>
      import('views/auth-views/authentication/login')
    ),
  },
  {
    key: 'register',
    path: `${AUTH_PREFIX_PATH}/register`,
    component: React.lazy(() =>
      import('views/auth-views/authentication/register')
    ),
  },
  {
    key: 'forgot-password',
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() =>
      import('views/auth-views/authentication/forgot-password')
    ),
  },
  {
    key: 'verify-otp',
    path: `${AUTH_PREFIX_PATH}/verify-otp`,
    component: React.lazy(() =>
      import('views/auth-views/authentication/verify-otp')
    ),
  },
  {
    key: 'reset-password',
    path: `${AUTH_PREFIX_PATH}/reset-password`,
    component: React.lazy(() =>
      import('views/auth-views/authentication/reset-password')
    ),
  },
];

export const protectedRoutes = [
  {
    key: 'dashboard.default',
    path: `${APP_PREFIX_PATH}/dashboards/default`,
    component: React.lazy(() => import('views/app-views/dashboards/default')),
  },
  // {
  //   key: "ManageRoles",
  //   path: `${APP_PREFIX_PATH}/ManageRoles`,
  //   component: React.lazy(() => import("views/app-views/ManageRoles/index")),
  // },
  {
    key: 'product',
    path: `${APP_PREFIX_PATH}/product`,
    component: React.lazy(() => import('views/app-views/Product/index')),
  },
  {
    key: 'categories',
    path: `${APP_PREFIX_PATH}/categories`,
    component: React.lazy(() => import('views/app-views/Categories/index')),
  },
  {
    key: 'customer',
    path: `${APP_PREFIX_PATH}/ManageRoles/customer`,
    component: React.lazy(() =>
      import('views/app-views/ManageRoles/Customer/customer')
    ),
  },
  {
    key: 'vendor',
    path: `${APP_PREFIX_PATH}/ManageRoles/vendor`,
    component: React.lazy(() =>
      import('views/app-views/ManageRoles/Vendor/vendor')
    ),
  },
  {
    key: 'admin',
    path: `${APP_PREFIX_PATH}/ManageRoles/admin`,
    component: React.lazy(() =>
      import('views/app-views/ManageRoles/Admin/admin')
    ),
  },
  {
    key: 'createCategory',
    path: `${APP_PREFIX_PATH}/categories/components/createCategory`,
    component: React.lazy(() =>
      import('views/app-views/Categories/components/createCategory')
    ),
  },
  {
    key: 'addStates',
    path: `${APP_PREFIX_PATH}/states/components/addStates`,
    component: React.lazy(() =>
      import('views/app-views/States/components/addStates')
    ),
  },
  {
    key: 'newProducts',
    path: `${APP_PREFIX_PATH}/product/components/newProducts`,
    component: React.lazy(() =>
      import('views/app-views/Product/components/newProducts')
    ),
  },
  {
    key: 'newBulkUpload',
    path: `${APP_PREFIX_PATH}/product/components/newBulkUpload`,
    component: React.lazy(() =>
      import('views/app-views/Product/components/newBulkUpload')
    ),
  },
  {
    key: 'productTrackList',
    path: `${APP_PREFIX_PATH}/product/components/productTrackList`,
    component: React.lazy(() =>
      import('views/app-views/Product/components/productTrackList')
    ),
  },
  {
    key: 'bulkUpdtae',
    path: `${APP_PREFIX_PATH}/product/components/bulkUpdate`,
    component: React.lazy(() =>
      import('views/app-views/Product/components/bulkUpdate')
    ),
  },
  {
    key: 'states',
    path: `${APP_PREFIX_PATH}/states`,
    component: React.lazy(() => import('views/app-views/States/index')),
  },
  {
    key: 'styles',
    path: `${APP_PREFIX_PATH}/styles`,
    component: React.lazy(() => import('views/app-views/Styles/index')),
  },
  {
    key: 'banners',
    path: `${APP_PREFIX_PATH}/banners`,
    component: React.lazy(() => import('views/app-views/Banners/index')),
  },
  {
    key: 'videos',
    path: `${APP_PREFIX_PATH}/videos`,
    component: React.lazy(() => import('views/app-views/Videos/index')),
  },
  {
    key: 'crazyDeals',
    path: `${APP_PREFIX_PATH}/crazyDeals`,
    component: React.lazy(() => import('views/app-views/CrazyDeals/index')),
  },
  {
    key: 'subCategories',
    path: `${APP_PREFIX_PATH}/subCategories/`,
    component: React.lazy(() => import('views/app-views/SubCategories/index')),
  },
  {
    key: 'aboutUs',
    path: `${APP_PREFIX_PATH}/aboutUs`,
    component: React.lazy(() => import('views/app-views/AboutUs/index')),
  },
  {
    key: 'createAbout',
    path: `${APP_PREFIX_PATH}/aboutUs/components/createAbout`,
    component: React.lazy(() =>
      import('views/app-views/AboutUs/components/createAbout')
    ),
  },
  {
    key: 'editAbout',
    path: `${APP_PREFIX_PATH}/aboutUs/components/editAbout`,
    component: React.lazy(() =>
      import('views/app-views/AboutUs/components/editAbout')
    ),
  },
  {
    key: 'privacyPolicy',
    path: `${APP_PREFIX_PATH}/privacyPolicy`,
    component: React.lazy(() => import('views/app-views/PrivacyPolicy/index')),
  },
  {
    key: 'createPrivacyPolicy',
    path: `${APP_PREFIX_PATH}/privacyPolicy/components/createPrivacyPolicy`,
    component: React.lazy(() =>
      import('views/app-views/PrivacyPolicy/components/createPrivacyPolicy')
    ),
  },
  {
    key: 'editPrivacyPolicy',
    path: `${APP_PREFIX_PATH}/privacyPolicy/components/editPrivacyPolicy`,
    component: React.lazy(() =>
      import('views/app-views/PrivacyPolicy/components/editPrivacyPolicy')
    ),
  },
  {
    key: 'sizeChart&policy',
    path: `${APP_PREFIX_PATH}/sizeChart&policy/`,
    component: React.lazy(() =>
      import('views/app-views/SizeChart&Policy/index')
    ),
  },
  {
    key: 'addSizeChart',
    path: `${APP_PREFIX_PATH}/SizeChart&Policy/components/addSizeChart`,
    component: React.lazy(() =>
      import('views/app-views/SizeChart&Policy/components/addSizeChart')
    ),
  },
  {
    key: 'addRefundPolicy',
    path: `${APP_PREFIX_PATH}/SizeChart&Policy/components/addRefundPolicy`,
    component: React.lazy(() =>
      import('views/app-views/SizeChart&Policy/components/addRefundPolicy')
    ),
  },
  {
    key: 'shippingCharges',
    path: `${APP_PREFIX_PATH}/SizeChart&Policy/components/shippingCharges`,
    component: React.lazy(() =>
      import('views/app-views/SizeChart&Policy/components/shippingCharges')
    ),
  },
  {
    key: 'sellerBanner',
    path: `${APP_PREFIX_PATH}/components/SellerBanner/index`,
    component: React.lazy(() =>
      import('views/app-views/VendorDashboard/SellerBanner')
    ),
  },
  {
    key: 'updateSellerBanner',
    path: `${APP_PREFIX_PATH}/sellerBanner/updateSellerBanner`,
    component: React.lazy(() =>
      import('views/app-views/VendorDashboard/SellerBanner/updateSellerBanner')
    ),
  },
  {
    key: 'editCategory',
    path: `${APP_PREFIX_PATH}/categories/components/editCategory/:_id`,
    component: React.lazy(() =>
      import('views/app-views/Categories/components/editCategory')
    ),
  },
  {
    key: 'editBanner',
    path: `${APP_PREFIX_PATH}/banners/components/editBanner/:_id`,
    component: React.lazy(() =>
      import('views/app-views/Banners/components/editBanner')
    ),
  },
  {
    key: 'editSubCategories',
    path: `${APP_PREFIX_PATH}/subCategories/components/editSubCategories/:_id`,
    component: React.lazy(() =>
      import('views/app-views/SubCategories/components/editSubCategories')
    ),
  },
  {
    key: 'createBanner',
    path: `${APP_PREFIX_PATH}/banners/components/createBanner`,
    component: React.lazy(() =>
      import('views/app-views/Banners/components/createBanner')
    ),
  },
  {
    key: 'createVideos',
    path: `${APP_PREFIX_PATH}/videos/components/createVideos`,
    component: React.lazy(() =>
      import('views/app-views/Videos/components/createVideos')
    ),
  },
  {
    key: 'createBanner',
    path: `${APP_PREFIX_PATH}/crazyDeals/components/createCrazyDeals`,
    component: React.lazy(() =>
      import('views/app-views/CrazyDeals/components/createCrazyDeals')
    ),
  },
  {
    key: 'CreateSubCategories',
    path: `${APP_PREFIX_PATH}/SubCategories/components/createSubCategories`,
    component: React.lazy(() =>
      import('views/app-views/SubCategories/components/createSubCategories')
    ),
  },
  {
    key: 'videoListing',
    path: `${APP_PREFIX_PATH}/videos/components/videoListing`,
    component: React.lazy(() =>
      import('views/app-views/Videos/components/videoListing')
    ),
  },
  {
    key: 'editVideo',
    path: `${APP_PREFIX_PATH}/videos/components/editVideo/:id`,
    component: React.lazy(() =>
      import('views/app-views/Videos/components/editVideo')
    ),
  },
  {
    key: 'editStates',
    path: `${APP_PREFIX_PATH}/states/components/editStates/:_id`,
    component: React.lazy(() =>
      import('views/app-views/States/components/editStates')
    ),
  },
  {
    key: 'editCrazyDeals',
    path: `${APP_PREFIX_PATH}/CrazyDeals/components/editCrazyDeals/:_id`,
    component: React.lazy(() =>
      import('views/app-views/CrazyDeals/components/editCrazyDeals')
    ),
  },
  {
    key: 'viewProduct',
    path: `${APP_PREFIX_PATH}/product/components/viewProduct/:slug`,
    component: React.lazy(() =>
      import('views/app-views/Product/components/viewProduct')
    ),
  },
  {
    key: 'editProduct',
    path: `${APP_PREFIX_PATH}/product/components/editProduct/:slug/:_id`,
    component: React.lazy(() =>
      import('views/app-views/Product/components/editProduct')
    ),
  },
  {
    key: 'createStyles',
    path: `${APP_PREFIX_PATH}/styles/components/createStyles`,
    component: React.lazy(() =>
      import('views/app-views/Styles/components/createStyles')
    ),
  },
  {
    key: 'editStyles',
    path: `${APP_PREFIX_PATH}/styles/components/editStyles/:_id`,
    component: React.lazy(() =>
      import('views/app-views/Styles/components/editStyles')
    ),
  },
  {
    key: 'AdminOrders',
    path: `${APP_PREFIX_PATH}/AdminOrders`,
    component: React.lazy(() => import('views/app-views/AdminOrders/index')),
  },
  {
    key: 'Festive',
    path: `${APP_PREFIX_PATH}/festive`,
    component: React.lazy(() => import('views/app-views/Festive/index')),
  },
  {
    key: 'NewFestiveCategory',
    path: `${APP_PREFIX_PATH}/festive/components/AddNewFestiveCategory`,
    component: React.lazy(() =>
      import('views/app-views/Festive/components/AddNewFestiveCategory')
    ),
  },
  {
    key: 'UpdateFestiveCategory',
    path: `${APP_PREFIX_PATH}/festive/components/UpdateFestiveCategory/:_id`,
    component: React.lazy(() =>
      import('views/app-views/Festive/components/UpdateFestiveCategory')
    ),
  },
  //orders
  {
    key: 'orders',
    path: `${APP_PREFIX_PATH}/orders`,
    component: React.lazy(() => import('views/app-views/Orders/index')),
  },
  {
    key: 'orderDetails',
    path: `${APP_PREFIX_PATH}/orders/components/orderDetails/:orderId/:productId`,
    component: React.lazy(() =>
      import('views/app-views/Orders/components/orderdetail')
    ),
  },
  {
    key: 'ApproveVendor',
    path: `${APP_PREFIX_PATH}/ManageRoles/vendor/ApproveVendor/:_id`,
    component: React.lazy(() =>
      import('views/app-views/ManageRoles/Vendor/ApproveVendor')
    ),
  },

  // vendor settlements'

  {
    key: 'AdminVendorSettlement',
    path: `${APP_PREFIX_PATH}/adminVendorSettlement`,
    component: React.lazy(() =>
      import('views/app-views/AdminVendorSettlement/index')
    ),
  },
  {
    key: 'AdminVendorSettlementDetail',
    path: `${APP_PREFIX_PATH}/adminVendorSettlement/component/AdminVendorSettlementDetail/:_id`,
    component: React.lazy(() =>
      import(
        `views/app-views/AdminVendorSettlement/component/VendorSettlementDetails`
      )
    ),
  },
  {
    key: 'vendorSettlement',
    path: `${APP_PREFIX_PATH}/vendorSettlement`,
    component: React.lazy(() =>
      import('views/app-views/VendorSettlement/index')
    ),
  },

  {
    key: 'vendorSettlementDetails',
    path: `${APP_PREFIX_PATH}/vendorSettlement/components/vendorSettlementDetails/:orderId`,
    component: React.lazy(() =>
      import(
        `views/app-views/VendorSettlement/components/vendorSettlementDetails`
      )
    ),
  },

  // Vendor Dashboard
  {
    key: 'profile',
    path: `${APP_PREFIX_PATH}/profile`,
    component: React.lazy(() =>
      import('views/app-views/VendorDashboard/index')
    ),
  },
  {
    key: 'sellerBanner',
    path: `${APP_PREFIX_PATH}/sellerBanner`,
    component: React.lazy(() =>
      import('views/app-views/VendorDashboard/SellerBanner/index')
    ),
  },
  {
    key: 'basicVendorDetails',
    path: `${APP_PREFIX_PATH}/VendorDashboard/Components/basicVendorDetails`,
    component: React.lazy(() =>
      import('views/app-views/VendorDashboard/Components/basicVendorDetails')
    ),
  },
  {
    key: 'advanceVendorDetails',
    path: `${APP_PREFIX_PATH}/VendorDashboard/Components/advanceVendorDetails`,
    component: React.lazy(() =>
      import('views/app-views/VendorDashboard/Components/advanceVendorDetails')
    ),
  },

  // Vendor Sign Up mail mssg

  {
    key: 'SignUpMailMssg',
    path: `${APP_PREFIX_PATH}/vendor-registration-notifications`,
    component: React.lazy(() =>
      import('views/app-views/VendorRegistrationMessages')
    ),
  },
  {
    key: 'OnBoardingApprovalMssg',
    path: `${APP_PREFIX_PATH}/On-Boarding-Approval-Mssg`,
    component: React.lazy(() =>
      import('views/app-views/VendorBoardingApprovalMessage')
    ),
  },

  // review Approval

  {
    key: 'ReviewApproval',
    path: `${APP_PREFIX_PATH}/reviewApproval`,
    component: React.lazy(() => import('views/app-views/ReviewApproval/index')),
  },

  {
    key: 'ReviewApprovalDetails',
    path: `${APP_PREFIX_PATH}/reviewApproval/components/ReviewApprovalDetails/:id`,
    component: React.lazy(() =>
      import('views/app-views/ReviewApproval/components/ReviewApprovalDetails')
    ),
  },
];
